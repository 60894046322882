<!--
 * @version: v 1.0.0
 * @Github: https://github.com/GitHubGanKai
 * @Author: GitHubGanKai
 * @Date: 2020-12-27 20:41:48
 * @LastEditors: gankai
 * @LastEditTime: 2021-01-02 20:29:34
 * @FilePath: /refactor-with-vue3/src/views/home/index.vue
-->
<template>
  <div  class="home">
<!--    <van-overlay :show="showShadow" class="shadow">-->
<!--      <van-loading color="hsl(123, 98%, 49%)" size="24px">绿色通道登录中...</van-loading>-->
<!--    </van-overlay>-->
<!--    <van-nav-bar title="数字徽章" :safe-area-inset-top="false"   fixed :border="false"-->
<!--      class="nav" >-->
<!--    </van-nav-bar>-->
    <div class="banner">
      <van-swipe class="swiper-carousel" lazy-render :autoplay="3000"  :show-indicators="false"    @change="onChange">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="openBanner(item.url)">
          <!-- @click="handleClick" -->
          <van-image radius="8px" fit="cover" class="lazy_img" :src="item.img" />
        </van-swipe-item>
      </van-swipe>

      <ul class="indicators indicator">
        <li v-for="(item, index) in bannerList" :class=" bannerActive == index ? 'active' : ''" :key="index" ></li>
      </ul>

    </div>

    <div class="bannerBig">
      <van-swipe class="swiper-carousel" lazy-render :autoplay="3000"  :show-indicators="false"    @change="onChangeBottom">
        <van-swipe-item v-for="(item, index) in bottomBanner" :key="index" @click="openBanner(item.url)">
          <!-- @click="handleClick" -->
          <van-image radius="8px" fit="cover" class="lazy_img_2" :src="item.img" />
        </van-swipe-item>
      </van-swipe>

      <ul class="indicators indicator">
        <li v-for="(item, index) in bottomBanner" :class=" bannerActiveBottom == index ? 'active' : ''" :key="index" ></li>
      </ul>

    </div>


    <div id="main-filter" class="main-body">
<!--      <p>{{errorstr}}</p>-->
      <van-sticky>
        <div class="content-tabs">
          <van-tabs @click-tab="clickTab" v-model:active="tabActive" shrink sticky line-width="40px" line-height="3px" title-active-color="#000000">
            <van-tab v-for="item in tabArray" :title="item.title" :name="item.idx" :key="item.idx">

            </van-tab>
          </van-tabs>

        </div>
      </van-sticky>


      <div v-if= "(!ifFirstAppear) && productPageList.length <= 0" class="nodata">
        <img src="@/assets/common/icon_nodata.png" alt="">
            无藏品信息
      </div>
      <div v-else class="main-bottom">
        <section class="goods-box">
          <ul class="goods-content">
            <li v-for="(item, index) in productPageList" :key="index">
              <router-link tag="div" :to="{ path: '/nftDetail', query: { productId: item.id } }" class="cell-item">
                <van-image radius="18px 18px 0 0" class="product-img" fit="cover" :src="item.images">
                </van-image>
                <div v-show="getTimeMs(item.dateMap,item.seckillStock) !== ''" v-html="getTimeMs(item.dateMap,item.seckillStock)" class="startTimeDiv"></div>
                <div class='goods-div'>
                  <p class="product-name">
                    {{ item.name}}
                  </p>
                  <div class="other">
                    <div class="other-left">
                      <div class="limit-num">
                        <div> 限量</div>
                        <div> {{ item.total }}</div>
                      </div>
                      <p class="price">
                        免费
                      </p>
                    </div>

                    <div class="other-right">
                      免费领取
                    </div>
                  </div>


                </div>

              </router-link>
            </li>
          </ul>
        </section>
      </div>

<!--      <div class="login" v-show="submitData.show">-->
<!--        <van-cell-group inset id="yzm" >-->
<!--          <van-field-->
<!--            center-->
<!--            clearable-->
<!--            label=""-->
<!--            placeholder="请输入手机号"-->
<!--            class="cell"-->
<!--            maxlength="11"-->
<!--            v-model="submitData.phone"-->
<!--            type="number"-->
<!--          >-->
<!--            <template #button>-->

<!--              <div  v-if="submitData.times > 0">-->
<!--                {{submitData.times}}s后重新发送-->
<!--              </div>-->
<!--              <van-button v-else  @click="sendYzm" size="small" type="primary">{{submitData.sendMsg}}</van-button>-->

<!--            </template>-->
<!--          </van-field>-->
<!--        </van-cell-group>-->
<!--        <van-cell-group inset id="psw" >-->
<!--          <van-field-->
<!--            center-->
<!--            clearable-->
<!--            label=""-->
<!--            placeholder="请输入短信验证码"-->
<!--            class="cell"-->
<!--            maxlength="6"-->
<!--            v-model="submitData.code"-->
<!--            type="number"-->
<!--          >-->
<!--          </van-field>-->
<!--        </van-cell-group>-->

<!--        <van-button  class="login_btn" @click="login">-->
<!--          登录-->
<!--        </van-button >-->

<!--      </div>-->


      <div class="botton-introduce">
        <div>
          <img class="chain-icon" src="../../assets/icons/chain_logo_big.png">
        </div>
        <div class="chain-botton">提供区块链技术服务支持</div>
      </div>
    </div>


    <Certification></Certification>
    <tabbar></tabbar>

  </div>
</template>

<script>
import {ref, reactive, onMounted, toRefs, getCurrentInstance, onBeforeMount, onUnmounted, computed, watch} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import Certification from "@/components/certification";

import {
  changeToDate,
  clearToast,
  formatDate, getStore, loginToPlatform,
  setStore,
  showFailToast,
  showLoading,
  showSuccessToast
} from "../../utils/util";
import Cookies from 'js-cookie'
import {postUserSms, registerAndLogin} from "../../api/user";

export default {
  name: "home",
  components: {
    Certification
  },
  setup(props, context) {
    const { proxy } = getCurrentInstance();
    const $store = useStore();
    var timer;

    const submitData = reactive({
      phone:'',
      code:'',
      checked : false,
      times:0,
      sendMsg:'发送验证码',
      show:localStorage.getItem('token') == null ? true : false,

    });



    const  sendYzm = () => {




        showLoading('短信发送中...');
      proxy.$http.post('/wenwu-user/utils/sms',{phone:submitData.phone}).then(res => {



          if(res.data.code === 200){

            submitData.sendMsg = '再次发送'

            submitData.times = 59;
            timer = setInterval(() => {

              submitData.times -= 1;
              if(submitData.times <= 0){
                clearInterval(timer);
              }

            }, 1000);
            showSuccessToast('发送短信成功',1000);
          }else{
            showFailToast(res.data.message,1000);
          }

        }).catch((err) => {
          showFailToast(err.message,1000);
        })







    }
    const  login = () => {

      if( submitData.code.trim().length <= 0){
        showFailToast('请输入验证码',1000)
        return
      }

      showLoading('登录中')

      proxy.$http.post('/wenwu-user/user/login',{phone:submitData.phone,code:submitData.code}).then(res => {


        if(res.data.code === 200){

          setStore("token", res.data.data.satoken)
          setStore("isValid", res.data.data.isValid)
          showSuccessToast('登录成功');
          window.location.reload();
        }else{
          showFailToast(res.data.message);
        }

      }).catch((err)=>{
        showFailToast('登录失败');
      });


    }


    // ctx.$store === $store  ==>true 其实是同一个对象！

    const $router = useRouter();
    const $route = useRoute(); // 可以拿到所有和路由相关的参数
    const active = ref(0);
    const timeData = ref(36000000);
    const headerActive = ref(false);
    const showShadow = ref(false);

    const state = reactive({
      errorstr:'',
      currentTime:null,
      bannerActive:0,
      bannerActiveBottom:0,
      bannerList: [],
      bottomBanner: [],
      tabActive:0,
      productPageList: [],
      tabArray: [
        {
          title: "全部",
          idx: 0
        },
        {
          title: "进行中",
          idx: 1
        },
        {
          title: "已结束",
          idx: 2
        }],
      ifFirstAppear:true,
    });



    var timerNow;

    const startNowTimer = () => {

      if(timerNow){
        clearInterval(timerNow)
      }
      timerNow = setInterval(() => {
          state.currentTime = new Date(state.currentTime.getTime() +   300)
      }, 300);
    }
    // dateMap,seckillStock
    const getTimeMs = computed(() => (dateMap,seckillStock)=>{

      let date =  new Date(dateMap.year , dateMap.month -1 , dateMap.day , dateMap.hour ,dateMap.minutes,dateMap.second);
      //判断如果在/在开始12小时前--直接显示时间
      if(date.getTime() - state.currentTime.getTime() >= 24*60*60*1000 ){
         return '敬请期待&nbsp;&nbsp;' + formatDate({ date: date, formatStr: 'yyyy-MM-dd HH:mm' });
      }else if (date.getTime() - state.currentTime.getTime() > 0) {//在开始12小时 - 开始倒计时

        return   '即将开始&nbsp;&nbsp;' + formatTime(date.getTime() - state.currentTime.getTime());
      }else if(seckillStock <= 0){

        return  '已领完';
      }

      return '';

    })

    const formatTime = (time) => {

      let hours = parseInt(time  / (1000 * 60 * 60));

      let minutes = parseInt((time % (1000 * 60 * 60)) / (1000 * 60));

      let seconds = Math.floor((time % (1000 * 60)) / 1000);

        return hours.toString().padStart(2,'0') + ':' + minutes.toString().padStart(2,'0') + ':' + seconds.toString().padStart(2,'0')


    }

    onUnmounted(()=>{

      document.removeEventListener("visibilitychange",func)
      if(timerNow){
        clearInterval(timerNow);
      }





    })




    proxy.$http.get("/wenwu-commodity/product/index/sass").then(res => {
      state.bannerList = res.data.data.bannerJson['1'];
      state.bottomBanner = res.data.data.bannerJson['2'];
    })
    proxy.$http.get("/wenwu-commodity/product/list?type=1&searchType="+state.tabActive+"&offset=0&limit=10").then(res => {
      if (res.data.code === 200) {

        state.ifFirstAppear = false

        if(res.data.data.now) {
          state.currentTime = changeToDate(res.data.data.now);
          //开启一个倒计时记录当前时间
          startNowTimer()
        }

      if (res.data.data.productList != null) {
        state.productPageList = []
        state.productPageList.push(...res.data.data.productList);
      } else {
        state.productPageList = []
      }
    }
    })
    const openBanner=((url)=>{
      // $router.push({
      //   path: "/home/openBanner",
      //   query: {url:url}
      // })
      window.location.href = url;
    })
    onMounted(() => {
      // getHwToken()

      document.addEventListener("visibilitychange", func);

      })

    const func = () => {
        if (document.hidden) {//处于当前页面
          console.log("hidden");
        }else{
          loadData();
        }

    }

    //   if(!getStore('token')) {
    //     // loginToPlatform(proxy.$http);
    //     let param = {
    //       uid: '2C05C8060ADC4AD881604CD820877774',
    //       urid: '53622286',
    //       token: '2aa4f96bb88641a09c2281a3980a087a'
    //     }
    //
    //     showLoading('登录中')
    //     proxy.$http.post("/wenwu-user/user/green/channel/hongwang", param).then(res => {
    //       clearToast()
    //       showSuccessToast('登录成功')
    //       setStore("token", res.data.data.satoken)
    //       setStore("isValid", res.data.data.isValid)
    //       setTimeout(() => {
    //         location.reload()
    //       }, 1000);
    //     }).catch((err) => {
    //       showFailToast('登录失败')
    //     })
    //   }
    // })


    window['transferUserDataToWeb'] = (uid,urid,token) => {

      let param = {
        uid: uid,
        urid: urid,
        token: token
      }
      showLoading('登录中')
      proxy.$http.post("/wenwu-user/user/green/channel/hongwang", param).then(res => {
        clearToast()
        showSuccessToast('登录成功')
        setStore("token", res.data.data.satoken)
        setStore("isValid", res.data.data.isValid)
        setTimeout(() => {
          location.reload()
        }, 1000);
      }).catch((err)=>{
        // state.errorstr = JSON.stringify(err);
        showFailToast('登录失败')
      })
    }

    const getHwToken = (() => {


      if(!getStore('token')){
        //获取红网登录令牌
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
          verifyJsBridge.postNotification('requireUserToLogin', '{}');
        } else if(isiOS) {
          window.webkit.messageHandlers.requireUserToLogin.postMessage('');
        }
      }

    })


    const onChange = (index) => {
      state.bannerActive = index;
    }

    const onChangeBottom = (index) => {
      state.bannerActiveBottom = index;
    }

    const clickTab = () => {
      document.querySelector("#main-filter").scrollIntoView(true);
      loadData()

    }


    const loadData = () => {

      showLoading();
      proxy.$http.get("/wenwu-commodity/product/list?type=1&searchType="+state.tabActive+"&offset=0&limit=10").then(res => {

        clearToast()

        if(res.data.code === 200  ){

          if(res.data.data.now) {
            state.currentTime = changeToDate(res.data.data.now);
            //开启一个倒计时记录当前时间
            startNowTimer()
          }

          if(res.data.data.productList != null){
            state.productPageList = []
            state.productPageList.push(...res.data.data.productList);
          }else{
            state.productPageList = []
          }

        }

      })
    }



    return {
      submitData,
        sendYzm,
        login,
      onChange,
      onChangeBottom,
      clickTab,
      getTimeMs,
      active,
      ...toRefs(state),
      showShadow,
      openBanner
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>

